<template>
     <div class="container-fluid">
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="true"/>
          <div class="row row-custom">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title">{{isNew?'اضافة منتج':'تعديل منتج'}}</h4>
                 </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <template v-if="isNew">
                          <Select id="category_id" title="التصنيف" :Items="CustomCategories" Mykey="category_id" :errors="service_error" :MyValue="form.category_id" @Select="HandleSelect('category_id',$event)"/>
                          <Select id="sub_category_id" title="التصنيف الفرعي" :Items="CustomSubCategories" Mykey="sub_category_id" :errors="service_error" :MyValue="form.sub_category_id" @Select="HandleSelect('sub_category_id',$event)"/>
                        </template>
                        
                        <Input id="name_ar" title="الاسم بالعربي" @Input="HandleNameInput('name','ar',$event)" Mykey="name.ar" :errors="service_error" :MyValue="form.name.ar"/>
                        <Input id="name_en" title="الاسم بالانجليزي" @Input="HandleNameInput('name','en',$event)" Mykey="name.en" :errors="service_error" :MyValue="form.name.en"/>
                        <Input id="selling_price" title="سعر البيع" @Input="HandleInput('selling_price',$event)" Mykey="selling_price" :errors="service_error" :MyValue="form.selling_price"/>
                        <Input id="selling_price_after_discount" title="سعر البيع بعد التخفيض" @Input="HandleInput('selling_price_after_discount',$event)" Mykey="selling_price_after_discount" :errors="service_error" :MyValue="form.selling_price_after_discount"/>
                        <Input id="selling_price_after_tax" title="سعر البيع بالضريبة" @Input="HandleInput('selling_price_after_tax',$event)" Mykey="selling_price_after_tax" :errors="service_error" :MyValue="form.selling_price_after_tax"/>
                        <Input id="quantity" title="الكمية" @Input="HandleInput('quantity',$event)" Mykey="quantity" :errors="service_error" :MyValue="form.quantity"/>
                        <Input id="brand" title="الماركة" @Input="HandleInput('brand',$event)" Mykey="brand" :errors="service_error" :MyValue="form.brand"/>
                        <Input id="modal" title="الموديل" @Input="HandleInput('modal',$event)" Mykey="modal" :errors="service_error" :MyValue="form.modal"/>
                        <Input id="tags" title="هاشتاق" @Input="HandleInput('tags',$event)" Mykey="tags" :errors="service_error" :MyValue="form.tags"/>
                        <Input id="offline_id" title="رقم الصنف" @Input="HandleInput('offline_id',$event)" Mykey="offline_id" :errors="service_error" :MyValue="form.offline_id"/>


                        <InputToggle @GiveMeIfo="HandleInputToggle('is_special',$event)" v-model="form.is_special" :MyValue="form.is_special"  id="is_special" title="صنف مميز" :errors="service_error" />
                        <InputToggle @GiveMeIfo="HandleInputToggle('is_available',$event)" v-model="form.is_available" :MyValue="form.is_available"  id="is_available" title="متاح" :errors="service_error" />
                        <InputToggle @GiveMeIfo="HandleInputToggle('is_there_discount',$event)" v-model="form.is_there_discount" :MyValue="form.is_there_discount"  id="is_there_discount" title="تخفيض" :errors="service_error" />
                        
                       
                       
                       <div class="col-md-12">
                          <WYSISYG id_c="description_ar_c" ref="description_ar"  id="description_ar" :value="form.description.ar" @content="HandleNameInput('description','ar',$event)" title="الوصف بالعربي"/>
                       </div>
                       <div class="col-md-12">
                          <WYSISYG id_c="description_en_c" ref="description_en"  id="description_en" :value="form.description.en" @content="HandleNameInput('description','en',$event)" title="الوصف بالانجليزي"/>
                       </div>

                       <div class="col-md-12">
                          <WYSISYG id_c="short_description_ar_c" ref="short_description_ar"  id="short_description_ar" :value="form.short_description.ar" @content="HandleNameInput('short_description','ar',$event)" title="وصف مختصر بالعربي"/>
                       </div>
                       <div class="col-md-12">
                          <WYSISYG id_c="short_description_en_c" ref="short_description_en" id="short_description_en" :value="form.short_description.en" @content="HandleNameInput('short_description','en',$event)" title="وصف مختصر بالانجليزي"/>
                       </div>

                       <Divider title="الالوان المتوفرة"/>
                      
                      <template v-if="ColorsToggleArray.length">
                       <InputToggle v-model="ColorsToggleArray[index].val" :MyValue="ColorsToggleArray[index].val" v-for="(color,index) in CustomColors" :key="index"  :id="color.id" :title="color.name" :errors="service_error" 
                          @GiveMeIfo="HandleColors($event,index)"
                           />
                      </template>

                    </div>

                    <button type="button" class="btn btn-primary pull-right" v-if="isNew"  @click="onSubmit()">حفظ </button>
                    <button type="button" class="btn btn-primary pull-right" v-else  @click="onUpdate()">تعديل </button>
                    <div class="clearfix"></div>



                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import Input from '../../../components/Form/TextInput'
import InputFile from '../../../components/Form/InputFile'
import {mapState,mapMutations} from 'vuex';
import store from '../../../store/index'
import SuccessAlert from '../../../components/SuccessAlert'
import Divider from '../../../components/Divider'
import WYSISYG from '../../../components/WYSISYG'
import InputToggle from '../../../components/Form/InputToggle'
import UploadFiles from "../../../components/UploadFiles";
import Select from '../../../components/Form/TextSelect'
export default {
  computed:{
    ...mapState({
        Product:state=>state.admin.products.Product.data,
        service_error:state=>state.admin.products.Product.errors,
        CategoriesList:state=>state.admin.categories.CategoriesList,
        SubCategoriesList:state=>state.admin.subcategories.SubCategoriesList,
        ColorsList:state=>state.admin.colors.ColorsList,

    }),

    isNew(){
      return this.form.uuid == '';
    },

    CustomCategories(){
      let categories=[];
      this.CategoriesList.data.forEach(element => {
        categories.push({
          id:element.id,
          name:element.name.ar
        })
      });
      return categories;
    },
    CustomSubCategories(){
      let SubCategories=[];
      this.SubCategoriesList.data.forEach(element => {
        SubCategories.push({
          id:element.id,
          name:element.name.ar
        })
      });
      return SubCategories;
    },
    CustomColors(){
      let Colors=[];
      this.ColorsList.data.forEach(element => {
        Colors.push({
          id:element.id,
          name:element.name.ar
        })
      });
      return Colors;
    }
 
   },
   data(){
       return{
           form:{
               uuid:'',
               name:{
                   ar:'',
                   en:''
               },
               description:{
                   ar:'',
                   en:''
               },
               short_description:{
                   ar:'',
                   en:''
               },
               selling_price:'',
               selling_price_after_discount:'',
               selling_price_after_tax:'',
               is_special:false,
               is_available:false,
               quantity:'',
               brand:'',
               modal:'',
               tags:'',
               is_there_discount:false,

               category_id:'',
               sub_category_id:'',
               SelectedColors:[],

               offline_id:'',
               

           },
           ColorsToggleArray:[],
           AlertTitle:'',
           AlertMessage:'',
 
       }
   },
   methods:{
    ...mapMutations({
          cleanErrors: "admin/cleanProductsErrors"
       }),
     setData(vm){
            vm.form.uuid=vm.Product.uuid;
            vm.form.name.ar=vm.Product.name.ar;
            vm.form.name.en=vm.Product.name.en;
            vm.form.description.ar=vm.Product.description.ar;
            vm.form.description.en=vm.Product.description.en;
            vm.form.short_description.ar=vm.Product.short_description.ar;
            vm.form.short_description.en=vm.Product.short_description.en;

            vm.$refs.description_ar.value1=vm.Product.description.ar;
            vm.$refs.description_en.value1=vm.Product.description.en;
            vm.$refs.short_description_ar.value1=vm.Product.short_description.ar;
            vm.$refs.short_description_en.value1=vm.Product.short_description.en;


            vm.form.selling_price=vm.Product.selling_price;
            vm.form.selling_price_after_discount=vm.Product.selling_price_after_discount;
            vm.form.selling_price_after_tax=vm.Product.selling_price_after_tax;
            vm.form.is_special=vm.Product.is_special;
            vm.form.is_available=vm.Product.is_available;
            vm.form.quantity=vm.Product.quantity;
            vm.form.brand=vm.Product.brand;
            vm.form.modal=vm.Product.modal;
            vm.form.tags=vm.Product.tags;
            vm.form.is_there_discount=vm.Product.is_there_discount;

            vm.form.offline_id=vm.Product.offline_id;




            
        },
       
       onSubmit(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) {
            if(key == 'name'){
              formData.append('name[ar]', this.form[key].ar);
              formData.append('name[en]', this.form[key].en);
            }else if(key == 'description'){
              formData.append('description[ar]', this.form[key].ar);
              formData.append('description[en]', this.form[key].en);
            } else if(key == 'short_description'){
              formData.append('short_description[ar]', this.form[key].ar);
              formData.append('short_description[en]', this.form[key].en);
            }else if(key == 'SelectedColors'){
              for (let index = 0; index < this.form.SelectedColors.length; index++) {
                formData.append('SelectedColors['+index+']',this.form.SelectedColors[index]); 
              }
            }else{  
            formData.append(key, this.form[key]);
            }
          }
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/storeProduct',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },
       onUpdate(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) {
            if(key == 'name'){
              formData.append('name[ar]', this.form[key].ar);
              formData.append('name[en]', this.form[key].en);
            }else if(key == 'description'){
              formData.append('description[ar]', this.form[key].ar);
              formData.append('description[en]', this.form[key].en);
            } else if(key == 'short_description'){
              formData.append('short_description[ar]', this.form[key].ar);
              formData.append('short_description[en]', this.form[key].en);
            }else if(key == 'SelectedColors'){
              for (let index = 0; index < this.form.SelectedColors.length; index++) {
                formData.append('SelectedColors['+index+']',this.form.SelectedColors[index]); 
              }
            }else{  
            formData.append(key, this.form[key]);
            }
          }
          formData.append('_method', "patch");
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/updateProduct',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },

       fetchSubCategoriesList(){
         let category=this.CategoriesList.data.find(e=>e.id == this.form.category_id);
          store.dispatch('admin/fetchSubCategoriesList',{category_uuid:category.uuid,page:1,search:'',paginate:1,limit:2}).then(()=>{
            store.commit('admin/PleaseStopLoading');
 
          }).catch((error)=>{
            store.commit('admin/PleaseStopLoading');
 
          });    

       },
       HandleColors(value,index){
         this.ColorsToggleArray[index].val=value.val;
         if(value.status=="push"){
          this.form.SelectedColors.push(value.id); 
         }
         else{
           var index = this.form.SelectedColors.indexOf(value.id);
          if (index > -1) {
              this.form.SelectedColors.splice(index, 1);
          }
         }
         
       },
  
     HandleInput(key,value){
         this.form[key]=value;
     },
     HandleInputToggle(key,value){
         this.form[key]=value.val;
     },
     HandleNameInput(parent,key,value){
       console.log("------>>"+value);
         this.form[parent][key]=value;
     },
     HandleSelect(key,value){

       this.form[key]=value;
         if(key == 'category_id'){
           this.fetchSubCategoriesList();
         }
         
     },
   },
   components:{
       Input,
       SuccessAlert,
       InputFile,
       Divider,
       WYSISYG,
       InputToggle,
       UploadFiles,
       Select
    },
   
     beforeRouteEnter(to,from,next){  
      //   store.commit('admin/PleaseStartLoading');
        const uuid = to.params.uuid; 
       store.dispatch('admin/fetchColorsList',{}).then((response) => {
        if (typeof uuid === "undefined") {
          store.dispatch('admin/fetchCategoriesList',{page:1,search:'',paginate:1,limit:1000}).then(()=>{
            next(vm => {
              vm.ColorsList.data.forEach(element => {
                    vm.ColorsToggleArray.push({
                      id:element.id,
                      val:false,
                    });
                  });
             });
            });
         } else {
             store.dispatch('admin/fetchProduct', uuid).then((response) => {
                next(vm => {
                    vm.setData(vm);
                     vm.ColorsList.data.forEach(element => {
                      let exist=false;
                       for (let index = 0; index < response.data.data.colors.length; index++) {
                          if(element.id == response.data.data.colors[index].color_id){
                            vm.form.SelectedColors.push(element.id);
                            exist=true;
                          }
                       }
                        vm.ColorsToggleArray.push({
                          id:element.id,
                          val:exist?true:false,
                        });
                  });
                });
            });
        }
        });
        
    },
      beforeDestroy(){
         this.cleanErrors();
     }
}
</script>

<style lang="scss">
/*
 *
 */
.row-custom{
    justify-content: center;
}


















</style>